.relative {
  position: relative;
}

.w-100 {
  width: 100%;
}

.pointer {
  cursor: pointer;
}

.mr-5 {
  margin-right: 5px;
}

/* TabComponent.css */

.admin-create-strategy-container {
  display: flex;
  height: 100%;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 0.48px;
}

.admin-content-strategy-container {
  display: flex;
  height: 100%;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 0.48px;
  margin-bottom: 100px;
}

.admin-create-startegy-landing-page {
  width: 88%;
  margin-left: 26px;
}

.admin-create-strategy-tab-container {
  display: flex;
  align-items: center;
  width: 98%;
  justify-content: space-between;

  h5 {
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0.6px;
    margin-right: 5%;
  }

  .admin-create-strategy-tabs {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    border-bottom: 1px solid #b2b2b2b2;
    padding-left: 13px;
    box-sizing: border-box;
    margin-top: 10px;
    margin-bottom: 12px;
  }

  .admin-create-strategy-tabs h5 {
    margin-top: 0;
    margin-bottom: 0;
    padding: 18px 0px;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    font-style: normal;
    letter-spacing: 0.03em;
    text-align: left;
    cursor: pointer;
  }

  .admin-create-strategy-tabs h5:not(.active) {
    padding-bottom: 20px;
  }

  .admin-create-strategy-tabs .active {
    border-bottom: 2px solid #2747dd;
  }

  .tabs-container {
    display: flex;
    border-bottom: solid 1.5px #b2b2b2;
    margin-right: 10%;
  }

  .admin-header-buttons {
    display: flex;
  }
}

.tab {
  cursor: pointer;
  margin: 5px;
  padding: 10px;
  user-select: none;
  margin-bottom: 0;

  .number-icon {
    margin-right: 5px;
    padding: 2px 5px;
    border-radius: 50%;
    font-size: 16px;
    background-color: #b2b2b2;
    color: white;
  }
}

.tab:hover {
  background-color: #f3ecec;
}

.tab.active {
  border-bottom: 1.5px solid blue;

  .number-icon {
    background-color: #2747dd;
  }
}

.admin-save-button,
.admin-add-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  border-radius: 2.47px;
  background: #2747dd;
  height: 39px;
  padding: 0 15px;
  margin-right: 10px;
}

.admin-create-strategy-tab-content {
  height: auto;
  margin-top: 10px;
}

/* Admin Content Editing Page CSS */
.admin-content-editing-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
}

.admin-content-editing-heading {
  margin-left: 7px;
}

.admin-strategy-heading {
  color: rgba(0, 0, 0, 0.7);
  font-weight: 500;
}

.id-editor {
  display: flex;
  align-items: center;
  margin-top: 10px;

  input,
  span {
    padding: 4px;
    text-align: center;
    border-radius: 5px;
    background: #f3f6ff;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
    border: 1px solid rgba(0, 0, 0, 0.7);
    /* height: 20px; */
    /* margin-right: 5px; */
  }
}

.edit-icon {
  font-size: 25px !important;
}

.admin-content-editing-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* ImportSection.css */
.import-section {
  display: flex;
  align-items: center;
  width: 80%;
}

.import-button-section {
  padding: 10px 5px;
  position: relative;
  font-size: 14px;

  .import-button,
  .selected-image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #d9d9d9;
    height: 104px;
    width: 104px;
  }

  .edit-image-section {
    position: relative;
  }

  .selected-image {
    background-color: #fff;
  }

  .edit-image-icon {
    top: 0;
    right: 17%;
    position: absolute;
    border: solid;
    background-color: #fff;
  }
}

.strategy-name-info,
.strategy-tag {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  margin-left: 8%;

  label {
    text-align: left;
    padding: 10px;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.48px;
  }
}

.strategy-name-info {
  input {
    margin-right: 5px;
    width: 240px;
    height: 40px;
  }
}

.admin-content-middle-section {
  padding: 10px 0;
  width: 90%;

  .admin-content-middle-individual-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  label {
    padding: 20px 0px;
  }

  textarea {
    width: 84%;
    padding: 10px;
  }

  .trending-header {
    display: flex;
    align-items: center;

    article {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 15px;
      border-radius: 5px;
      width: 137px;
      background: #f3f6ff;
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
      padding: 5px 0;
    }

    span {
      font-weight: 100;
      font-size: 10px;
    }
  }
}

/* editor section */
.admin-content-editor {
  margin: 30px 0;
  text-align: left;
  width: 86%;

  label {
    padding: 10px;
    margin: 30px 0;
  }

  .text-editor {
    border: solid #b2b2b2b2;
    height: 100%;
    padding: 10px;
    margin-top: 15px;
    position: relative;

    .text-editor-header {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    .description-heading {
      font-size: 16px;
      font-weight: lighter;
      padding: 5px;
      margin: 10px;
    }
  }

  .overview-title {
    padding: 6px 1.3%;
    border: transparent;
    font-size: 20px;
    font-weight: 500;
  }

  .custom-wrapper-class {
    padding: 10px;
    border: 1px solid #b2b2b2b2;
    background-color: #f2f2f2;
  }

  .custom-editor-class {
    min-height: 150px;
  }

  .clear-all-btn {
    display: flex;
    align-items: center;
    color: #2747dd;
    position: absolute;
    right: 2%;
    bottom: 5%;
  }
}

/* qna section css */
.ques-ans-section {
  text-align: left;

  label {
    font-weight: bold;
  }

  .qna-ans {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 84%;
    border: solid;
    border: 1px solid rgba(178, 178, 178, 0.7);
    padding: 10px;
  }

  .qna {
    width: 98%;
    /* flex-direction: column; */
    label {
      min-width: 80px;
      margin-right: 4%;
    }

    input,
    textarea {
      padding: 5px 10px;
      width: 88%;
    }
  }

  .add-more-btn {
    width: 115px;
    background-color: #fff;
    color: #2747dd;
    border: solid black;
    padding: 5px 4px;
    border-radius: 10px;
    font-weight: 500;
    cursor: pointer;
  }
  .add-more-btn:hover {
    color: #fff;
    background-color: #2747dd;
  }
}

/* capital information section */

.capital-information-section {
  width: 86%;
  text-align: left;
  margin-top: 30px;

  .capital-info-part {
    /* border: solid; */
  }

  span {
    margin-bottom: 10px;
  }

  .capital-info-min-cap {
    margin: 20px 0 15px 0;
    display: flex;
    flex-direction: column;
    width: 100%;

    .admin-strategy-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .minimum-capital-input {
      display: flex;
      align-items: center;
      border: solid #a9a7a7;
      border-radius: 5px;
      padding: 10px 5px;
      width: 28%;
    }

    input {
      height: 23px;
      width: 100%;
      border: none;
      border-radius: 8px;
      background-color: #f1eeee;
      padding: 5px;
      margin: 0 5px;
    }

    .dummy-text {
      font-size: 11px;
      font-weight: 400;
      letter-spacing: 0.33px;
      width: 60%;
    }
  }
}

/* admin content risk section */

.admin-content-risk-section {
  .risk-options {
    display: flex;
    align-items: center;
  }

  .admin-risk-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .risk-option {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    border: solid #b2b2b2;
    padding: 5px 0;
    height: 55px;
    width: 70px;
    margin-right: 10px;
    font-size: 12.5px;
    font-weight: 290;
    border-width: thin;
  }

  .selected {
    border: solid 1.5px;
  }

  svg {
    padding: 10px;
    border-radius: 52%;
    background-color: rgba(178, 178, 178, 0.3);
  }
}

/* insights infor section */

.insights-information-section {
  text-align: left;
  padding: 10px 0;
  margin-top: 20px;
}

.insights-information-content {
  margin-top: 20px;
}

.blog-post-section {
  display: flex;
  margin-bottom: 40px;
}

.blog-image-section {
  position: relative;
  margin-right: 10px;

  margin-right: 10px;
}
.edit-blog-image {
  position: absolute;
  background-color: #fff;
  top: 12%;
  right: 7%;
}
.blog-selected-image {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  cursor: pointer;
}

.choose-icon-button {
  display: flex;
  font-size: 10px;
  flex-direction: column;
  align-items: center;
  text-align: center;
  cursor: pointer;
  background-color: #b2b2b2;
  color: black;
  border: solid;
  border-radius: 50%;
  margin-right: 10px;
  padding: 14px 10px !important;
}

.icon-section {
  display: flex;
  align-items: center;
  height: 75px;
  margin-right: 10px;
}

.blog-info {
  flex-grow: 1;
  width: 100%;

  input {
    width: 80%;
    padding: 10px;
    margin-top: 10px;
  }
}

.button-row {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.button-row button {
  margin-right: 10px;
  display: flex;
  align-items: center;
  background-color: #fff;
}

.admin-create-strategy-btn {
  width: 150px;
  background: transparent;
  border: solid black;
  border-radius: 15px;
  cursor: pointer;
  font-weight: 500;
  height: 45px;
  font-size: 16px;
  color: #2847dd;
}

.admin-create-strategy-btn:hover {
  background-color: #d4d6de;
}

/* Strategy Setting css */
.strategy-key-paramters-container {
  display: flex;
  flex-direction: column;
}

.strategy-key-paramters-tables,
.expiry-factors-container,
.hedges-factors-container,
.strategy-key-paramters-table,
.heading-section-table-container {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin-bottom: 30px;

  .strategy-key-paramters-table1,
  .strategy-key-paramters-table2 {
    width: 450px;
    height: 429px;
    top: 73px;
    left: 487px;
    border-radius: 5px;
    border: 1px;
  }

  table {
    width: 100%;
    background: #f3f6ff;
    box-shadow: #a9a7a7 5px;
  }

  .underline {
    border: solid rgb(193, 188, 188) 1px;
    margin: 0 10px;
  }

  th {
    padding: 5px;
  }

  tr {
    display: flex;
    justify-content: space-between;
    padding: 7px;
  }
}

.expiry-factors-container {
  flex-direction: column;

  tr {
    display: flex;
    align-items: center;
  }

  th {
    margin-right: 10px;
    width: 8%;
  }

  td {
    float: left;
  }
}

.hedges-factors-container {
  flex-direction: column;

  table {
    overflow-x: 'auto';
  }
}

.strategy-key-paramters-table {
  table {
    padding: 10px;
  }

  td {
    width: 200px;
    margin-right: 30px;
    justify-content: space-between;
  }

  thead {
    display: flex;

    /* border-bottom: solid; */
    tr {
      width: 94%;
      justify-content: flex-start;
    }

    th {
      width: 43%;
    }
  }

  th {
    padding-left: 0;
    /* max-width: 30%; */
  }
}

.heading-section-table-container {
  flex-direction: column;

  .heading-section-table {
    background: #f3f6ff;

    table {
      width: auto;
      overflow-x: scroll !important;
      padding: 0;
    }

    thead {
      tr {
        width: 123%;
      }

      th {
        min-width: 16%;
      }

      .underline {
        width: 150%;
      }
    }

    tbody {
      tr {
        justify-content: space-between;
        width: 135%;
        text-align: left;

        td {
          min-width: 15%;
          text-align: center;
          padding-left: -30px;
        }
      }
    }
  }
}

.strategy-key-paramters-heading {
  display: flex;
  float: left;
  align-items: center;
  height: 50px;
  margin-bottom: 15px;
  font-weight: bold;

  .underline-container {
    display: flex;
    position: relative;
    margin-right: 10px;
    margin-top: 5px;
  }

  .underline-text {
    padding-bottom: 10px;
    border-bottom: solid;
  }

  .circle {
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #000;
    top: 95%;
    right: -5px;
    transform: translateY(-50%);
  }
}

.strategy-settings-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  flex: 1;
  height: 100%;
  box-shadow: 0px 4px 4px 0px #00000040;
  border: 1px solid #b2b2b2b2;
  border-radius: 5px;
  background: #f3f6ff;
}

.strategy-settings-container-first {
  padding: 24px;
  margin: 0px;
  width: 100%;
  box-sizing: border-box;
  padding-right: 72px;
  padding-bottom: 0px;
}

.strategy-settings-container-first-inner {
  padding: 0px;
  margin: 0px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
}

.strategy-settings-title {
  padding-left: 9px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.05em;
  text-align: left;
  color: #2747dd;
}

.strategy-settings-config-container {
  padding-left: 9px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 18px;
  width: 100%;
  box-sizing: border-box;
}

.strategy-settings-config-container
  .strategy-settings-config-container-parts:first-child {
  width: 100%;
}

.strategy-settings-config-container-parts {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
}

.strategy-settings-config-name {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.03em;
  text-align: left;
}

.strategy-settings-config-input-text {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.03em;
  text-align: left;
  padding: 4px 16px;
  margin: 0px;
  background: #ffffff;
  border-radius: 5px;
  border: none;
  outline: none;
  flex: 1;
}

.strategy-settings-config-type {
  margin-right: 7px;
  margin-left: 41px;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.03em;
  text-align: left;
}

.strategy-settings-config-type-select {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.03em;
  text-align: left;
  padding: 4px 16px;
  margin: 0px;
  background: #ffffff;
  border-radius: 5px;
  border: none;
  outline: none;
  min-width: 131px;
}

.strategy-settings-add-config {
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.strategy-settings-add-config button {
  background: #2747dd;
  border: none;
  outline: none;
  border-radius: 2.47px;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.03em;
  text-align: left;
  padding-top: 8px;
  padding-bottom: 7px;
  padding-right: 13px;
  padding-left: 20px;
  color: #ffffff;
  cursor: pointer;
  margin-left: 9px;
}

.strategy-settings-add-config button svg {
  margin-right: 11px;
}

.strategy-settings-container-first-inner hr {
  width: 100%;
  margin-top: 28px;
  height: 1px;
  background: #b2b2b2b2;
  border: none;
  margin-bottom: 0px;
  padding: 0px;
}

.strategy-settings-container-second {
  padding: 20px 13px;
  margin: 0px;
  width: 100%;
  box-sizing: border-box;
  padding-right: 80px;
}

.strategy-settings-container-second-inner {
  padding: 0px;
  margin: 0px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
}

.strategy-settings-container-second-grid-contatiner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px;
  margin: 0px;
  width: 100%;
}

.strategy-settings-container-second-grid {
  display: grid;
  grid-template-columns: 1fr minmax(auto, 369px) 200px;
  width: 100%;
  grid-template-rows: auto;
  justify-items: flex-start;
  align-items: flex-start;
}

.strategy-settings-container-second-grid-title {
  border: 1px solid #b2b2b2b2;
  padding: 11px 20px;
  box-sizing: border-box;
  border-radius: 3px;
}

.strategy-settings-container-second-grid-title > div {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.05em;
  text-align: left;
  color: #2747dd;
}

.strategy-settings-container-second-grid-title > div:not(:first-child) {
  padding-left: 19px;
}

.strategy-settings-container-second-grid-title-seperator {
  position: absolute;
  height: 100%;
  width: 1px;
  background: #b2b2b2b2;
  left: 0px;
}

.strategy-settings-container-second-grid:not(
    .strategy-settings-container-second-grid-title
  )
  > div:not(:first-child) {
  padding-left: 19px;
  box-sizing: border-box;
}

.strategy-settings-container-second-grid:not(
    .strategy-settings-container-second-grid-title
  ) {
  padding: 11px 20px;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
}

/* .strategy-settings-container-second-grid:not(.strategy-settings-container-second-grid-title) > div:first-child{
  overflow-x: scroll;
} */

.strategy-settings-container-second-grid
  .strategy-settings-container-second-grid-add-new-field-btn {
  font-family: 'Poppins', sans-serif;
  font-size: 11px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0.03em;
  text-align: left;
  padding: 3px 14px;
  box-shadow: 0px 0.7741935849189758px 1.5483871698379517px 0px #00000040;
  border: 0.77px solid #000000b2;
  background: #ffffff;
  border-radius: 3.87px;
  cursor: pointer;
}

.strategy-settings-add-btn-container {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  grid-auto-rows: 46px;
  justify-items: flex-start;
  align-items: center;
}

.strategy-settings-add-btn-container > div {
  height: 25px;
  width: 100%;
}

.MuiTreeItem-label {
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: 'Poppins', sans-serif !important;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.1em;
  text-align: left;
  padding-left: 12px !important;
  width: 90%;
}

.MuiBox-root {
  max-width: 100% !important;
}

.strategy-settings-container-second-grid:not(
    .strategy-settings-container-second-grid-title
  )
  > div {
  margin-top: 18px;
  width: 100%;
}

.strategy-settings-type-container {
  display: grid;
  width: 100%;
  grid-template-rows: 1fr;
  justify-items: flex-start;
  align-items: flex-start;
}

.strategy-settings-type-container-unit {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 46px;
}

.admin-strategy-value-container-whole {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.admin-strategy-value-container {
  width: 0px;
  height: 100%;
}

.admin-strategy-value {
  /* width: 328px; */
  border-radius: 3px;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.1em;
  text-align: left;
  color: #000000b2;
  box-shadow: 0px 1px 5px 0px #00000040;
  background: #ffffff;
  position: absolute;
  top: 50%;
  left: 30px;
  transform: translate(0px, -50%);
  border: none;
  outline: none;
  padding-left: 15px;
  padding-top: 5px;
  padding-bottom: 4px;
}
