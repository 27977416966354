.admin-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  height: 100%;
  font-family: 'Poppins', sans-serif;
  align-items: flex-start;
  background: #fff;
  letter-spacing: 0.42px;
  position: relative;

  .admin-navbar {
    width: 20%;
    display: flex;
    align-items: flex-start;
    height: 87.5vh;
  }

  .admin-landing-page {
    display: flex;
    width: 78%;
    height: 89vh;
    flex-direction: column;
    position: relative;
    padding: 10px 10px 10px 0;
    transition: all 0.3s ease-in-out;
  }

  .admin-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 5px;
  }

  .admin-tabs {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    width: 80%;
    border-bottom: solid #b2b2b2b2;
    padding-top: 15px;
    margin-bottom: 30px;
  }

  .admin-tabs .tab-option {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 10px;
    /* padding-top: 10px; */
    background-color: transparent;
    cursor: pointer;
    border-bottom: solid #2747dd 2px;
    font-size: 16px;
    letter-spacing: 0.48px;
    height: 20px;
    max-width: 30%;
  }
  .tab-option-responsive {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 5px 10px;
    padding-top: 10px;
    background-color: transparent;
    cursor: pointer;
    width: 100%;
    gap: 13px;
  }

  .sortby-options {
    /* flex-direction: column; */
    position: absolute;
    z-index: 999;
    top: 85%;
    background-color: #ffffff;
    margin-left: 80px;
    width: 100%;
    /* border: solid; */
    border-radius: 5px;
    overflow: hidden;
    padding: 10px 12px;
    padding-right: 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 13px;
    /* border: 1px solid #000000b2; */
    box-shadow: 1px 1px 10px 0px #00000040;
  }

  .admin-tab-content {
    text-decoration: none;
    min-width: 100%;
  }

  .dropdown-icon {
    font-size: 8px !important;
    width: 8px;
    height: 4px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }

  .search-bar {
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    border-bottom: solid #2747dd 2px;
    width: 27%;
  }

  .search-input {
    flex-grow: 1;
    background-color: transparent;
    border: none;
    outline: none;
  }

  input::placeholder {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.48px;
  }

  .create-strategy-button {
    width: 15%;
    height: 36px;
    border-radius: 2.47px;
    background: #2747dd;
    color: #ebe7e7;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    letter-spacing: 0.48px;
  }

  /* table css */
  .admin-table {
    width: 100%;

    border-collapse: collapse;
    margin-bottom: 20px;

    thead {
      width: 100%;
      display: flex;

      tr {
        justify-content: flex-start;
        width: 100%;
        align-items: center;
        border-bottom: 1px solid gray;
      }
    }
  }

  .admin-table tr {
    padding: 14px 0;
    display: flex;
    align-items: center;
    border-bottom: 1px solid gray;
  }

  .admin-table th {
    color: rgba(37, 37, 37, 0.78);
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  td {
    justify-content: center;
    color: #000000b2;
    white-space: nowrap;
    text-align: start;
    /* margin-right: 1%;
    min-width: 5%; */
    .toggle {
      position: relative;
      display: inline-block;
      width: 55px;
      height: 18.5px;
      border-radius: 30px;
      border: 1px solid gray;
      background: #f82929;
      box-shadow: 0px 5.158px 5.158px 0px rgba(0, 0, 0, 0.13) inset;
    }

    .toggle:after {
      content: '';
      position: absolute;
      width: 16.5px;
      height: 16.5px;
      border-radius: 50%;
      background: #fff;

      box-shadow: 0px 5.158px 5.158px 0px rgba(46, 46, 46, 0.25) inset;
      top: 1px;
      left: 1px;
      transition: all 0.5s;
    }

    p {
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: Arial, Helvetica, sans-serif;
      font-weight: bold;
      margin-top: 5px;
    }

    .checkbox:checked + .toggle::after {
      left: 36px;
    }

    .checkbox:checked + .toggle {
      background-color: #2acd1c;
    }

    .checkbox {
      display: none;
    }

    img {
      width: 40px;
      height: 38px !important;
      border-radius: 3.791px;
    }

    .admin-btn-collection {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
    }
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      width: 90px;
      height: 30px;
      display: flex;
      border-radius: 10px;
      background-color: #f3f6ff;
      border: 2px solid rgba(0, 0, 0, 0.7);
    }
    .admin-page-icon {
      width: 15px;
      height: 18px;
      margin-right: 1px;
    }
  }

  .admin-risk-data {
    width: 81px;
    height: 24px;
    border-radius: 3px;
    border: solid 1px;
    text-align: center;
  }

  /* Toggle bar styles */
  td:first-child {
    display: flex;
    align-items: center;
  }

  td:nth-child(2) img {
    max-width: 100%;
    height: auto;
  }
  .sortby-options {
    /* flex-direction: column; */
    position: absolute;
    z-index: 999;
    top: 85%;
    background-color: #ffffff;
    margin-left: 80px;
    width: 100%;
    /* border: solid; */
    border-radius: 5px;
    overflow: hidden;
    padding: 10px 12px;
    padding-right: 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 13px;
    /* border: 1px solid #000000b2; */
    box-shadow: 1px 1px 10px 0px #00000040;
  }

  .sort-option {
    background: #ffffff;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 300;
    line-height: 21px;
    letter-spacing: 0.03em;
    text-align: left;
    margin: 0;
    border-radius: 0;
    padding: 0px;
    border: 'none';
  }
}

/* delete strategy popup css */

.delete-strategy-popup-card {
  position: fixed;
  top: 50%;
  left: 50%;
  background: #ffffff;
  border-radius: 8px;
  height: 150px;
  width: 400px;
  color: rgb(125, 122, 122);
  border: solid 0.5px;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px;

  p {
    font-size: 18px;
    color: #000000;
    font-weight: 500;
    margin: 0px;
  }

  .btn-container {
    gap: 10%;
    margin-top: 10px;
  }

  button {
    font-family: poppins;
    border: none;
    border-radius: var(--border-radius);
    background-color: var(--primary-backround-color);
    color: var(--button-text-color);
    padding: 6pt 10pt;
    font-size: 12pt;
    cursor: pointer;
    margin: 4pt;
    height: 40px;
    width: 120px;
  }

  .outlined-btn {
    border: solid 0.5px;
    background-color: transparent;
    color: black;
  }
}


.confirm-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.confirm-dialog p {
  margin-bottom: 20px;
}

.confirm-dialog button {
  margin-right: 10px;
}
