.attribute-section {
  margin-top: 20px;
}

.attributes-table {
  width: 100%;
  border-collapse: collapse;
}

.attributes-table th,
.attributes-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.icon {
  cursor: pointer;
  margin-right: 5px;
}

.edit-icon,
.delete-icon {
  cursor: pointer;
  margin-left: 10px;
}

.value-table {
  margin-top: 10px;
}

.value-table table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.value-table th,
.value-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.multi-level-table-show {
  border: solid blue;
  width: 80%;
  display: flex;
  table {
    width: 80%;
    border: solid;
    display: flex;
    flex-direction: column;
    tr {
      padding: 10px;
    }
    thead {
      tr {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
    tbody {
      tr {
        width: 100%;
        display: flex;

        align-items: center;
        justify-content: space-between;
        td {
          display: flex;
        }
      }
    }

    .attribute-name-container {
      display: flex;

      width: 300px;
    }

    button {
      align-items: center;
      width: 30px;
      height: 15px;
    }
  }
}
