.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.kill-switch-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .strategy-heading {
    font-size: 1.5em;
    margin-top: 22px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0.03em;
    text-align: left;
  }

  .strategy-kill-switch-card {
    width: 95%;
    display: flex;
    justify-content: space-between;
    box-shadow: 0px 0px 4px 0px #00000040;
    margin-top: 18px;
    box-sizing: border-box;
    background: #f3f6ff;
    margin-top: 18px;
    padding-top: 21px;
    padding-bottom: 22px;
    padding-left: 28px;
    padding-right: 31px;
  }

  .strategy-kill-switch-card-first-half {
    display: flex;
    /* align-items: center; */
    width: 23%;
    /* justify-content: space-around; */
    margin: 10px;
    .strategy-kill-switch-card-text {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      font-family: Poppins;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.03em;
      text-align: left;

      p {
        padding: 0px;
        margin-top: 8px;
        margin-bottom: 0px;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 300;
        line-height: 21px;
        letter-spacing: 0.03em;
        text-align: left;
      }
    }
  }

  .strategy-kill-switch-card-second-half {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 50%;
  }
}

.kill-switch-toggle-btn {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  /* width: 50%; */
  width: 100px;
}

.toggle-container {
  position: relative;
  width: 50px;
  height: 17px;
  background-color: #cbcaca;
  border-radius: 15px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.slider {
  position: absolute;
  top: -4px;
  /* left: 6px; */
  width: 25px;
  height: 25px;
  background-color: #050505;

  border-radius: 50%;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s;
}

.toggle-container.active {
  /* background-color: #4caf50; */
}

.slider.active {
  transform: translateX(120%);
  background-color: rgba(84, 104, 196, 1);
}

.admin-kill-switch-landing-page {
  margin-left: 0px;
  padding-left: 0px;
  box-sizing: border-box;
  margin-right: 21px;
}

.admin-kill-switch-tabs {
  position: relative;
  right: 13px;
  margin-bottom: 0px !important;

  h5 {
    max-width: 163px;
    white-space: nowrap;
    font-family: 'Poppins', sans-serif !important;
    font-size: 20px !important;
    font-weight: 500 !important;
    line-height: 30px !important;
    letter-spacing: 0.03em !important;
    text-align: left !important;
  }
}

.kill-id {
  display: flex;
  align-items: center;
  margin-top: 18px;

  span {
    text-align: center;
    border-radius: 5px;
    background: #f3f6ff;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
    border: 1px solid rgba(0, 0, 0, 0.7);
    height: 20px;
    padding-top: 2px;
    padding-bottom: 1px;
    padding-left: 28px;
    padding-right: 27px;
    color: #000000b2;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.03em;
    text-align: left;
  }
}

.strategy-kill-switch-details {
  margin-top: 42px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  box-shadow: 0px 0px 4px 0px #00000040;
  background: #f3f6ff;
  border-radius: 5px;
}

.strategy-kill-switch-details-tabs {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  cursor: pointer;
}

.strategy-kill-switch-details-tabs div:first-child {
  border-top-left-radius: 5px;
}

.strategy-kill-switch-details-tabs div:last-child {
  border-top-right-radius: 5px;
}

.strategy-kill-switch-details-tab {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 16px 0px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.03em;
  text-align: left;
  background: #ffffff;
  color: #2747dd;
}

.strategy-kill-switch-details-tab-active {
  background: #2747dd;
  color: #ffffff;
  box-shadow: 0px 4px 4px 0px #00000040;
}

.strategy-kill-switch-details-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
  box-sizing: border-box;
}

.strategy-kill-switch-details-content-matrices {
  width: 100%;
  border: 1px solid #b2b2b2;
  padding-top: 26px;
  padding-bottom: 23px;
  padding-left: 27px;
  padding-right: 35px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 35px;
}

.strategy-kill-switch-details-content-matrices-part-1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.03em;
  text-align: left;
  color: #2747dd;

  p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 300;
    line-height: 21px;
    letter-spacing: 0.03em;
    text-align: left;
    color: #000000b2;
    margin-top: 7px;
    margin-bottom: 0px;
    padding: 0px;
  }
}

.strategy-kill-switch-details-content-matrices-part-2 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0.03em;
  text-align: left;
  color: #2747dd;
}

.strategy-kill-switch-details-content-algo {
  width: 100%;
  display: flex;
  background: #ffffff;
  border-top-left-radius: 5px;
  flex-direction: column;
  padding-left: 15px;
  padding-top: 17px;
  padding-right: 16px;
  padding-bottom: 16px;
}

.strategy-kill-switch-details-content-algo-top {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.strategy-kill-switch-details-content-algo-top-filter {
  margin-top: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000b2;
  border: 0.82px solid #000000b2;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 8px;
  padding-right: 0px;
  border-radius: 4.12px;
  cursor: pointer;
}

.strategy-kill-switch-details-content-algo-top-prev-next {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 28px;
  flex-direction: row;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000b2;
  cursor: pointer;
}

.strategy-kill-switch-details-content-algo-table-container {
  margin: 19px 0px;
  width: 100%;
  overflow-x: scroll;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.strategy-kill-switch-details-content-algo-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0px;

  th {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0em;
    color: #000000b2;
  }

  td {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    color: #000000b2;
  }

  th,
  td {
    border: 1px solid black;
    padding: 8px;
    text-align: left;
    background: transparent;
    border: 1px solid #b2b2b2;
  }

  th:first-child,
  td:first-child {
    text-align: center;
  }

  th:first-child {
    border-top-left-radius: 5px;
  }

  th:last-child {
    border-top-right-radius: 5px;
  }

  tr:last-child td:first-child {
    border-bottom-left-radius: 5px;
  }

  tr:last-child td:last-child {
    border-bottom-right-radius: 5px;
  }
}
